import { RouteRecordRaw } from 'vue-router';

import { defaultLayoutComponents } from '~/pages/layout';
import { RouteKeys } from '~/router/routes.domain';
import { storeToRefs } from 'pinia';
import { useRolesStore } from '~/stores';
import { computed } from 'vue';

import { StatisticsRoutes } from '~/pages/pm/statistics/statistics-routes';
import { AzureSprintRoutes } from '~/pages/pm/azure-sprint/azure-sprint.routes';
import { RoadmapRoutes } from '~/pages/pm/roadmap/roadmap.routes';

export const releaseNoteRoutes: RouteRecordRaw = {
	path: 'release-note',
	name: RouteKeys.RELEASE_NOTE,
	components: {
		default: () => import('./ReleaseNoteView.vue'),
		...defaultLayoutComponents
	},
	meta: {
		requiresAuth: true,
		isForbidden: () => {
			const { isPM } = storeToRefs(useRolesStore());
			return computed(() => !isPM.value);
		},
		label: 'pm.releaseNote.navigation',
		isSubEntry: true,
		order: 41
	}
};

export const PMRoute: RouteRecordRaw = {
	path: '/pm',
	name: RouteKeys.PM_HOME,
	components: {
		default: () => import('./pm.vue'),
		...defaultLayoutComponents
	},
	redirect: StatisticsRoutes,
	children: [StatisticsRoutes, releaseNoteRoutes, ...AzureSprintRoutes, ...RoadmapRoutes],
	meta: {
		isNavEntry: true,
		isForbidden: () => {
			const { isPM } = storeToRefs(useRolesStore());
			return computed(() => !isPM.value);
		},
		label: 'pm.navigation',
		icon: 'pen-ruler',
		requiresAuth: true,
		order: 6
	}
};
