import { storeToRefs } from 'pinia';
import { computed } from 'vue';
import { RouteRecordRaw } from 'vue-router';

import { defaultLayoutComponents } from '~/pages/layout';
import { RouteKeys } from '~/router/routes.domain';
import { useRolesStore } from '~/stores';

export const RoadmapListRoute: RouteRecordRaw = {
	path: 'roadmap',
	name: RouteKeys.PM_ROADMAP,
	components: {
		default: () => import('./RoadmapListView.vue'),
		...defaultLayoutComponents
	},
	meta: {
		requiresAuth: true,
		isForbidden: () => {
			const { isPM } = storeToRefs(useRolesStore());
			return computed(() => !isPM.value);
		},
		label: 'pm.roadmap.navigation',
		isSubEntry: true,
		order: 41
	}
};

export const RoadmapDetailsRoute: RouteRecordRaw = {
	path: 'roadmap/:roadmapId/:roadmapArea',
	name: RouteKeys.PM_ROADMAP_DETAILS,
	components: {
		default: () => import('./RoadmapView.vue'),
		...defaultLayoutComponents
	},
	meta: {
		requiresAuth: true,
		isForbidden: () => {
			const { isPM } = storeToRefs(useRolesStore());
			return computed(() => !isPM.value);
		}
	}
};
export const RoadmapEditRoute: RouteRecordRaw = {
	path: 'roadmap/edit/:roadmapId',
	name: RouteKeys.PM_ROADMAP_EDIT,
	components: {
		default: () => import('./RoadmapEditView.vue'),
		...defaultLayoutComponents
	},
	meta: {
		requiresAuth: true,
		isForbidden: () => {
			const { isSuperAdmin } = storeToRefs(useRolesStore());
			return computed(() => !isSuperAdmin.value);
		}
	}
};
export const RoadmapGoalEditRoute: RouteRecordRaw = {
	path: 'roadmap/:roadmapId/goal/:roadmapGoalId/edit',
	name: RouteKeys.PM_ROADMAP_GOAL_EDIT,
	components: {
		default: () => import('./RoadmapGoalEditView.vue'),
		...defaultLayoutComponents
	},
	meta: {
		requiresAuth: true,
		isForbidden: () => {
			const { isSuperAdmin } = storeToRefs(useRolesStore());
			return computed(() => !isSuperAdmin.value);
		}
	}
};


export const RoadmapRoutes: RouteRecordRaw[] = [RoadmapListRoute, RoadmapDetailsRoute, RoadmapEditRoute, RoadmapGoalEditRoute];
